import axios from 'axios';
import { Firebase } from 'services';

export const AxiosPublicFirebaseInstance = axios.create({
  baseURL: process.env.REACT_APP_CLOUD_FUNCTIONS_BASE_URL,
});

export const AxiosPrivateFirebaseInstance = axios.create({
  baseURL: process.env.REACT_APP_CLOUD_FUNCTIONS_BASE_URL,
});

AxiosPrivateFirebaseInstance.interceptors.request.use(
  async (config) => {
    const token = await Firebase.auth.currentUser.getIdToken();

    config.headers.Authorization = `Bearer ${token}`;

    return config;
  },
  (error) => Promise.reject(error)
);
