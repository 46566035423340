import { FC, useEffect, useState } from "react";
import db from "services/firestore";
import { AxiosPrivateFirebaseInstance } from "settings";
import { Notify, openQueryWithTimestamp, toCurrencyNumber } from "utils";
import { Button, Table, Typography, Row } from "antd";
import { TExternalBalance } from "types/externalBalances";
import dayjs from "dayjs";
import { IIntegrationBase } from "services/firebase";
import { Firebase } from "services";

const { Title, Paragraph } = Typography;

const subscribeToExternalBalances = (entityId: string, callback) => {
  try {
    const subscribe = db
      .collection("entities")
      .doc(entityId)
      .collection("externalBalances")
      .onSnapshot((query) =>
        callback(openQueryWithTimestamp(query, ["_created", "_updated"]))
      );

    return subscribe;
  } catch (error) {
    console.log(error);
  }
};

const refreshExternalBalances = async (entityId: string) =>
  AxiosPrivateFirebaseInstance.post(
    `/admin_actions/integrations/${entityId}/external-balances/refresh`
  );

const ExternalBalances: FC<{ entityId: string; allowWrite: boolean }> = ({
  entityId,
  allowWrite,
}) => {
  const [externalBalances, setExternalBalances] = useState<TExternalBalance[]>(
    []
  );
  const [isRefreshingExternalBalances, setIsRefreshingExternalBalances] =
    useState(false);
  const [integrations, setIntegrations] = useState<IIntegrationBase[]>([]);
  const hasConnectedIntegrations =
    integrations.filter((integration) => integration.status === "connected")
      .length > 0;

  useEffect(() => {
    if (entityId) {
      Firebase.getIntegrations(entityId).then((data) => setIntegrations(data));

      const unsubscribe = subscribeToExternalBalances(
        entityId,
        setExternalBalances
      );

      return () => {
        unsubscribe?.();
      };
    }
  }, [entityId]);

  const onRefreshExternalBalances = async () => {
    try {
      setIsRefreshingExternalBalances(true);

      await refreshExternalBalances(entityId);
    } catch (error) {
      console.warn("Failed to refresh external balances. Error: ", error);
      Notify.error(error);
    } finally {
      setIsRefreshingExternalBalances(false);
    }
  };

  const externalBalancesColumns = [
    {
      title: "Last updated",
      dataIndex: "_created",
      key: "_created",
      render: (date, record: TExternalBalance) =>
        dayjs(record._updated ?? record._created).format(
          "DD MMM YYYY HH:mm:ss"
        ),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (text, record: TExternalBalance) => {
        return (
          <div className="currencyAmount">
            {toCurrencyNumber(record.amount)}&nbsp;
          </div>
        );
      },
    },
    {
      title: "Currency",
      dataIndex: "buy",
      key: "buy",
      render: (text, record: TExternalBalance) => {
        return (
          <div className="currencyAmount">
            <b>{record.currency}</b>
          </div>
        );
      },
    },
    {
      title: "Account name",
      dataIndex: "accountName",
      key: "accountName",
      render: (value) => <p>{value}</p>,
    },
    {
      title: "Account ID",
      dataIndex: "accountId",
      key: "accountId",
      render: (value) => <p>{value}</p>,
    },
    {
      title: "Source",
      dataIndex: "source",
      key: "source",
      render: (value) => <p>{value}</p>,
    },
  ];

  return (
    <>
      <Table
        title={() => (
          <Row justify="space-between" align="middle">
            <Title level={2}>External balances</Title>

            {hasConnectedIntegrations ? (
              <>
                {allowWrite ? (
                  <Button
                    style={{ marginLeft: "30px" }}
                    disabled={isRefreshingExternalBalances}
                    onClick={onRefreshExternalBalances}
                  >
                    {isRefreshingExternalBalances
                      ? "Loading..."
                      : "Refresh external balances"}
                  </Button>
                ) : null}
              </>
            ) : (
              <Paragraph type="danger">
                Requires reauthentication or not integrated
              </Paragraph>
            )}
          </Row>
        )}
        columns={externalBalancesColumns}
        dataSource={externalBalances}
      />
    </>
  );
};

export default ExternalBalances;
