import { FC, useState, useEffect, Fragment } from "react";
import { Button, message, Typography } from "antd";

import { openQuery, toCurrencyNumber } from "utils";

import db from 'services/firestore';
import { IBalance } from "types";
import { AmountText, AmountWithAvailableAmountText, BalancesContentWrapper, LedgerAmountText, Separator } from "./Balances.styles";
import { Link } from "react-router-dom";
import { refreshBalances } from "services/firebase";

const subscribeToBalances = (entityId: string, callback) => {
  try {
    const subscribe = db
      .collection("entities")
      .doc(entityId)
      .collection("balances")
      .onSnapshot((query) => callback(openQuery(query)));

    return subscribe;
  } catch (error) {
    console.log(error);
    return undefined;
  }
};

const { Title } = Typography;

interface IOwnProps {
  entityId?: string;
}

const Balances: FC<IOwnProps> = ({ entityId }) => {
  const [balances, setBalances] = useState<IBalance[]>([]);
  const [isRefreshingBalances, setIsRefreshingBalances] = useState(false);

  useEffect(() => {
    if (entityId) {
      const unsubscribeToBalances = subscribeToBalances(entityId, setBalances);

      return () => {
        unsubscribeToBalances && unsubscribeToBalances();
      };
    }
  }, [ entityId ]);

  const onRefreshBalances = async () => {
    try {
      setIsRefreshingBalances(true);

      await refreshBalances(entityId);
    } catch (error) {
      console.warn("Failed to refresh balances. Error: ", error);
      message.error(error);
    } finally {
      setIsRefreshingBalances(false);
    }
  };

  return (
    <>
      <Title level={3}>Balances</Title>
      <BalancesContentWrapper>
        {balances.length ? (
          balances.map((balance, index) => {
            if (balance.availableAmount !== undefined) {
              const actualAmountFromLedger = balance.actualAmount || 0;
              const isDiscrepancyInActualAmounts = Math.abs(balance.amount - actualAmountFromLedger) > 0.01;
              const discrepancyStyle = isDiscrepancyInActualAmounts ? { color: 'red' } : {};
              return (
                <Fragment key={balance.id}>
                  {index > 0 && <Separator />}
                  <div>
                    <span style={ discrepancyStyle } >
                      <AmountWithAvailableAmountText>
                        {balance.currency} {toCurrencyNumber(balance.amount)}
                      </AmountWithAvailableAmountText>
                    </span>
                    <LedgerAmountText>
                      Available {toCurrencyNumber(balance.availableAmount)}
                    </LedgerAmountText>
                    <span style={ discrepancyStyle } >
                      <LedgerAmountText>
                        Physical {toCurrencyNumber(balance.actualAmount || 0)}
                      </LedgerAmountText>
                    </span>
                  </div>
                </Fragment>
              );
            } else {
              return (
                <Fragment key={balance.id}>
                  {index > 0 && <Separator />}
                  <AmountText>{balance.currency} {toCurrencyNumber(balance.amount)}</AmountText>
                </Fragment>
              );
            }
          })
        ) : (
          <h5>Balances are empty</h5>
        )}
        <Button
          style={{ marginLeft: "30px" }}
          onClick={onRefreshBalances}
        >
          {isRefreshingBalances ? "Loading..." : "Refresh balances"}
        </Button>
        <Separator />
        <Link to={`/app/ledger/${entityId}`}>
          Show ledger
        </Link>
      </BalancesContentWrapper>
    </>
  )
}

export default Balances;
